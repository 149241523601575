<div  mat-dialog-title>
    <h3 class="title-default-2">Escolha um lojista para abrir ou continuar um pedido</h3> 
</div>

@if(_consultorStoreResponse == undefined){
    <mat-dialog-content>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-dialog-content>
}

@if(_consultorStoreResponse != undefined){

    <mat-dialog-content>
        <div class="search-client">
            <form class="form" [formGroup]="model"  (ngSubmit)="searchClient()">
                <div class="row">
                    <div class="col-12 col-sm-8">
                        <mat-form-field appearance="outline">
                            <mat-label>Lojista</mat-label>
                            <input matInput formControlName="seach" type="text" focus>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                        <button type="submit" mat-flat-button class="btn-primary button-search">
                            @if (_isLoading == true) {
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                            }
                            @else{
                                Pesquisar
                            }
                        </button>
                    </div>
                </div>
            </form>
            @if(_consultorStoreResponse.listConsultorStore != null &&_consultorStoreResponse.listConsultorStore != undefined ){
                @for (consultorStore of _consultorStoreResponse.listConsultorStore ; track $index) {
                    <div class="row table-responsive">
                        <div class="col-sm-3">
                            <label class="bold">{{consultorStore.nameBusinessStore}}</label>
                        </div>
                        <div class="col-sm-3">
                            <label>{{consultorStore.ownerName}}</label>
                        </div>
                        <div class="col-sm-3">
                            <label class="bold orange">{{consultorStore.checkoutStatusName}}</label>
                        </div>
                        <div class="col-sm-2">
                            <label class="bold">{{consultorStore.totalPrice | currency:'BRL'}}</label>
                        </div>
                        <div class="col-sm-1">
                            <button mat-mini-fab class="fab-action orange" aria-label="Editar essa peça" (click)="selectBusinessStore(consultorStore.idBusinessStore)">
                                <mat-icon>arrow_forward</mat-icon>
                            </button>
                        </div>
                    </div>
                }
            }  
        </div>
    </mat-dialog-content>
}

<mat-dialog-actions>
    <button mat-flat-button [mat-dialog-close]="" >Fechar</button>  
    <button mat-flat-button class="btn-secundary" (click)="openInviteBusinessSotre()" >Convidar novo lojista</button>  
</mat-dialog-actions>
<!-- <mat-dialog-actions>
  <button mat-button >No Thanks</button>
  <button mat-button [mat-dialog-close]="" cdkFocusInitial>Ok</button>
</mat-dialog-actions> -->