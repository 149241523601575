import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { GetAllPaymentMethodsResponse } from '../../responses/checkout/get-all-payment-methods.response';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public GetAllPaymentMethods(): Observable<GetAllPaymentMethodsResponse> {
    let url: string = "PaymentMethod/GetAllPaymentMethods";
    
    return this.httpClient.get<GetAllPaymentMethodsResponse>(environment.urlApiCheckout + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}