import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Checkout } from '../../../../shared/services/models/orchestrator-checkout/checkout.model';
import { CheckoutService } from '../../../../shared/services/API/orchestator-checkout/checkout.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CheckoutAddressRequest } from '../../../../shared/services/requests/orchestator-checkout/checkout-address.request';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import { CheckoutResponse } from '../../../../shared/services/responses/orchestrator-checkout/checkout.response';
import { CEPCorreios, UtilService } from '../../../../shared/services/util.service';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { ReturnStruct } from '../../../../shared/services/structs/return.struct';

@Component({
  selector: 'app-modal-address',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    NgxMaskDirective],
  providers:[
    provideNgxMask()
  ],
  templateUrl: './modal-address.component.html',
  styleUrl: './modal-address.component.css'
})
export class ModalAddressComponent implements OnInit{

  constructor(private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private checkoutService:CheckoutService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<ModalAddressComponent>,
    public utilService:UtilService){

  }

  public model: FormGroup;
  public isLoading:boolean = false;

  ngOnInit(): void {
    let checkoutModel:Checkout = this.data.checkout;

    this.model = this.formBuilder.group({
      zipcode: [checkoutModel.zipcode, [Validators.required]],
      neighborhood: [checkoutModel.neighborhood, [Validators.required]],
      complement: [checkoutModel.complement],
      addressNumber: [checkoutModel.addressNumber, [Validators.required]],
      city: [checkoutModel.city, [Validators.required]],
      state: [checkoutModel.state, [Validators.required]],
      street: [checkoutModel.street, [Validators.required]],
      idCheckout: [checkoutModel.idCheckout],
    });
  }

  searchAddressCEP(){
    let cep:string = this.model.get('zipcode')?.value;

    this.utilService.getAddressByCEP(cep).subscribe({
      next:(cepCorreios:CEPCorreios) => {
        this.model.get('street')?.setValue(cepCorreios.logradouro);
        this.model.get('neighborhood')?.setValue(cepCorreios.bairro);
        this.model.get('city')?.setValue(cepCorreios.localidade);
        this.model.get('neighborhood')?.setValue(cepCorreios.bairro);
        this.model.get('state')?.setValue(cepCorreios.uf);
      }
    })
  }

  post(){
    if (this.model.invalid  || this.isLoading)
      return;

    this.isLoading = true;

    let checkoutAddressRequest:CheckoutAddressRequest = new CheckoutAddressRequest();
    checkoutAddressRequest.zipcode = this.model.get('zipcode')?.value;
    checkoutAddressRequest.neighborhood = this.model.get('neighborhood')?.value;
    checkoutAddressRequest.complement = this.model.get('complement')?.value;
    checkoutAddressRequest.addressNumber = this.model.get('addressNumber')?.value;
    checkoutAddressRequest.city = this.model.get('city')?.value;
    checkoutAddressRequest.state = this.model.get('state')?.value;
    checkoutAddressRequest.street = this.model.get('street')?.value;
    checkoutAddressRequest.country = 'Brasil';
    checkoutAddressRequest.idCheckout = this.model.get('idCheckout')?.value;

    this.checkoutService.updateCheckoutAdress(checkoutAddressRequest).subscribe({
      next: (response:ReturnStruct) => {
        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        let data = {
          address: checkoutAddressRequest
        }

        this.dialogRef.close(data);
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }
}
