import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { GetListBusinessCreditCardResponse } from '../../responses/checkout/get-list-business-credit-card.response';
import { CreateBusinessCreditCardRequest } from '../../requests/checkout/create-business-credit-card.request';
import { CreateBusinessCreditCardResponse } from '../../responses/checkout/create-business-credit-card.response';
import { ReturnStruct } from '../../structs/return.struct';

@Injectable({
  providedIn: 'root'
})
export class BusinessCreditCardService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public GetBusinessCreditCardsForUser(): Observable<GetListBusinessCreditCardResponse> {
    let url: string = "BusinessCreditCard/GetBusinessCreditCardsForUser";
    
    return this.httpClient.get<GetListBusinessCreditCardResponse>(environment.urlApiCheckout + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public CreateBusinessCreditCard(request: CreateBusinessCreditCardRequest): Observable<CreateBusinessCreditCardResponse> {
    let url: string = "BusinessCreditCard";
    
    return this.httpClient.post<CreateBusinessCreditCardResponse>(environment.urlApiCheckout + url, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public DeleteBusinessCreditCard(idBusinessCreditCard: number): Observable<ReturnStruct> {
    let url: string = `BusinessCreditCard/idBusinessCreditCard/${idBusinessCreditCard}`;
    
    return this.httpClient.delete<ReturnStruct>(environment.urlApiCheckout + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}