<app-menu></app-menu>
<app-search-bar></app-search-bar>
<div class="container">
  <div class="qr-code-container">
    <img [src]="'data:image/jpeg;base64,' + _pixQrCodeImage" alt="QR Code">
  </div>
  <div class="pix-code-container">
    <p>{{ _pixQrCode }}</p>
    <button mat-button type="button" class="btn-primary" (click)="copyPixCode()">Copiar</button>
  </div>

  <div class="value-container">
    <p>Valor: {{ _pixValue | currency:'BRL' }}</p>
  </div>
<!-- 
  <div class="timer-container">
    <p>Tempo restante para pagamento: {{ formattedTime }}</p>
  </div> -->
</div>
