<app-menu></app-menu>
<app-search-bar></app-search-bar>
<div class="container">
    <div class="row">
        <div class="col-12 col-md-8">
            @if(_checkoutResponse != undefined) {
                <div class="infos">
                    <h1 class="title-default">Pedido #{{_checkoutResponse.checkout.idCheckout}}</h1>
                </div>

                <div class="list-itens">
                    @if(_checkoutResponse != undefined) {
                        <mat-accordion class="business-checkout-accordion">
                            @for (businessCheckoutStruct of _checkoutResponse.listBusinessCheckoutStruct; track $index) {
                                <mat-expansion-panel hideToggle>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            {{businessCheckoutStruct.productBusinessName}}

                                        </mat-panel-title>
                                        <mat-panel-description>
                                            <div class="price-mat-panel-description">
                                                @if(businessCheckoutStruct.businessCheckoutDiscount) {
                                                    <span class="text-discount-strike">{{businessCheckoutStruct.totalPrice | currency:'BRL'}}</span>
                                                    <span class="text-discount">{{businessCheckoutStruct.totalPrice - businessCheckoutStruct.businessCheckoutDiscount.discountValue | currency:'BRL'}}</span>
                                                } @else {
                                                    {{businessCheckoutStruct.totalPrice | currency:'BRL'}}
                                                }
                                            </div> 
                                            <div class="business-payment-info">
                                                <button mat-button class="btn-primary btn-send-link" (click)="openPaymentMethodModal(businessCheckoutStruct)">
                                                    @if(!businessCheckoutStruct.hasPaymentInfo) {
                                                        Selecione o método de pagamento
                                                    } @else {
                                                        Alterar método de pagamento
                                                    }
                                                    <span class="material-symbols-outlined">arrow_forward</span>
                                                </button>    
                                                @if(businessCheckoutStruct.hasPaymentInfo) {
                                                    <div class="tag-with-payment">  
                                                        {{businessCheckoutStruct.paymentDisplayText}}                                                    
                                                    </div>  
                                                } 
                                            </div>                                           
                                           
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    @if(businessCheckoutStruct.businessCheckoutDiscount) {
                                        <p class="info-discount">
                                            Desconto de {{businessCheckoutStruct.businessCheckoutDiscount.discountValue | currency:'BRL'}} aplicado
                                            <br />
                                            {{businessCheckoutStruct.businessCheckoutDiscount.discountMotive}}
                                        </p>
                                    }
                                    @for (productCheckoutStruct of businessCheckoutStruct.listProductCheckoutStruct; track $index) {
                                        <div class="checkout-item">
                                            <div class="row">
                                                <div class="col-3">
                                                    <img class="image-item" src="{{productCheckoutStruct.productImageUrl}}" />
                                                </div>
                                                <div class="col-7">
                                                    <label class="business-iten-price">{{productCheckoutStruct.productCheckout.totalPrice | currency: "BRL"}}</label>
                                                    <label class="business-name">{{businessCheckoutStruct.productBusinessName}}</label>
                                                    <label class="business-iten-name">{{productCheckoutStruct.productCheckout.productName}}</label>
                                                    <label class="business-iten-count">{{productCheckoutStruct.productCheckout.totalQuantity}} peças</label>
                                                    <label class="business-iten-count-description">2 cores escolhidas em 3 grades de tamanho</label>
                                                </div>
                                                <div class="col-2 text-center">
                                                    <div class="vertical-aling-out">
                                                        <div class="vertical-aling-inner">
                                                            <button mat-mini-fab class="fab-action orange" aria-label="Ver grade" (click)="openDalogDetails(productCheckoutStruct.productCheckout.idProduct)">
                                                                <mat-icon>arrow_forward</mat-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </mat-expansion-panel>
                            }
                        </mat-accordion>
                    } @else {
                        <mat-accordion>
                            @for (item of returnArraySize(5); track $index) {
                                <mat-expansion-panel hideToggle>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <app-loading-box [height]="'15'" [width]="'120'"></app-loading-box>
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            <app-loading-box [height]="'15'" [width]="'120'"></app-loading-box>
                                        </mat-panel-description>
                                        <mat-action-row>
                                            <app-loading-box [height]="'25'" [width]="'120'"></app-loading-box>
                                        </mat-action-row>
                                    </mat-expansion-panel-header>
                                </mat-expansion-panel>
                            }
                        </mat-accordion>
                    }
                </div>
            } @else {
                <div class="infos">
                    <app-loading-box [height]="'33'" [width]="'310'" [marginTop]="'10'"></app-loading-box>
                    <app-loading-box [height]="'23'" [width]="'80'" [marginTop]="'10'"></app-loading-box>
                    <app-loading-box [height]="'23'" [width]="'210'" [marginTop]="'10'"></app-loading-box>
                    <app-loading-box [height]="'23'" [width]="'120'" [marginTop]="'10'"></app-loading-box>

                    <app-loading-box [height]="'23'" [width]="'500'" [marginTop]="'35'"></app-loading-box>
                    <app-loading-box [height]="'23'" [width]="'500'" [marginTop]="'5'"></app-loading-box>
                    <app-loading-box [height]="'23'" [width]="'500'" [marginTop]="'5'"></app-loading-box>
                    <app-loading-box [height]="'23'" [width]="'500'" [marginTop]="'5'"></app-loading-box>

                    <app-loading-box [height]="'33'" [width]="'310'" [marginTop]="'40'"></app-loading-box>
                </div>
            }
        </div>
        <div class="col-12 col-md-4">
            <div class="box-container">
                <div class="infos-resume">
                    <div class="row">
                        <div class="col-9">
                            <h2>Endereço de Entrega</h2>
                            @if(_checkoutResponse != undefined) {
                                <p>{{_checkoutResponse.checkout.businessStoreOwnerName}} - {{_checkoutResponse.checkout.businessStoreName}}</p>
                                <p>{{_checkoutResponse.checkout.street}}, {{_checkoutResponse.checkout.addressNumber}} - {{_checkoutResponse.checkout.complement}}</p>
                                <p>{{_checkoutResponse.checkout.city}} - {{_checkoutResponse.checkout.state}}</p>
                                <p>{{_checkoutResponse.checkout.zipcode}}</p>
                            } @else {
                                <app-loading-box [height]="'15'" [width]="'160'" [marginTop]="'10'"></app-loading-box>
                                <app-loading-box [height]="'15'" [width]="'120'" [marginTop]="'10'"></app-loading-box>
                                <app-loading-box [height]="'15'" [width]="'90'" [marginTop]="'10'"></app-loading-box>
                                <app-loading-box [height]="'15'" [width]="'110'" [marginTop]="'10'"></app-loading-box>
                            }
                        </div>
                        <div class="col-3">
                            @if(_checkoutResponse != undefined) {
                                <button mat-mini-fab class="fab-action orange" aria-label="Editar endereco" (click)="openModalAddress()">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            }
                        </div>
                    </div>
                </div>
                <div class="infos-resume">
                    <h3>Resumo do pedido</h3>
                    @if(_checkoutResponse != undefined) {
                        <div class="row">
                            <div class="col-sm-4">
                                <label class="infos-resume-label">Subtotal</label>
                            </div>
                            <div class="col-sm-8">
                                <label class="infos-resume-value">{{_checkoutResponse.totalPrice | currency:'BRL'}}</label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-4">
                                <label class="infos-resume-label">Desconto</label>
                            </div>
                            <div class="col-sm-8">
                                <label class="infos-resume-value">{{_checkoutResponse.totalDiscount | currency:'BRL'}}</label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-4">
                                <label class="infos-resume-label">Frete</label>
                            </div>
                            <div class="col-sm-8">
                                <label class="infos-resume-value small">Após recolher todos os pedidos nas confecções, o orçamento do frente fica disponível para aprovação</label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <label class="infos-resume-label total">Total do pedido</label>
                            </div>
                            <div class="col-sm-6">
                                <label class="infos-resume-value total">{{_checkoutResponse.totalPriceWithDiscount | currency:'BRL'}}</label>
                            </div>
                        </div>

                        <button type="button" class="btn-primary btn-send-link" (click)="createPayment()">
                            Comprar agora
                            <span class="material-symbols-outlined">arrow_forward</span>
                        </button>
                    } @else {
                        <app-loading-box [height]="'15'" [width]="'160'" [marginTop]="'10'"></app-loading-box>
                        <app-loading-box [height]="'15'" [width]="'120'" [marginTop]="'10'"></app-loading-box>
                        <app-loading-box [height]="'15'" [width]="'90'" [marginTop]="'10'"></app-loading-box>
                        <app-loading-box [height]="'15'" [width]="'110'" [marginTop]="'10'"></app-loading-box>
                    }
                </div>
            </div>
        </div>
    </div>
</div>
