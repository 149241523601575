import { Component, OnInit } from '@angular/core';
import { MenuComponent } from "../../../shared/component/menu/menu.component";
import { SearchBarComponent } from "../../../shared/component/search-bar/search-bar.component";
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { LoadingBoxComponent } from '../../../shared/component/loading-box/loading-box.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialog } from '@angular/material/dialog';
import { PaymentService } from '../../../shared/services/API/checkout/payment.service';
import { ConfirmModalComponent } from '../../../shared/component/confirm-modal/confirm-modal.component';
import { PaymentPixResponse } from '../../../shared/services/responses/checkout/payment-pix.response';

@Component({
  selector: 'app-checkout-pix-payment',
  standalone: true,
  imports: [
    MenuComponent,
    SearchBarComponent,
    MatExpansionModule,
    MatButtonModule,
    LoadingBoxComponent,
    CommonModule,
    MatIconModule,
    MatSlideToggleModule,
],
  templateUrl: './checkout-pix-payment.component.html',
  styleUrl: './checkout-pix-payment.component.css'
})
export class CheckoutPixPaymentComponent implements OnInit{
  constructor(  
    private alertService: AlertService,
    private activatedRoute:ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private paymentService: PaymentService){
  }
  
  public isLoading:boolean = false;
  public _idCheckout:number;
  private timerInterval: any;
  public formattedTime: string;
  public _pixQrCode: string;
  public _pixQrCodeImage: string;
  public _timeRemaining: number;
  public _pixValue: number;
  public _wasPaid: boolean;

  ngOnInit(): void {
    this._idCheckout = Number(this.activatedRoute.snapshot.paramMap.get('idCheckout'));

    this.getPixDataFromPayment();
    this.updateFormattedTime();
    // this.startTimer();
  }

  getPixDataFromPayment(){
    this.paymentService.getPixDataFromPayment(this._idCheckout).subscribe({

      next:(pixResponse: PaymentPixResponse)=>{
        if (pixResponse.isError) {
          this.alertService.show("Erro", pixResponse.errorDescription, AlertType.error);
          return;
        }

        if (pixResponse.wasPaid)
          this.router.navigate([`/store/checkout-done-to-store/${this._idCheckout}`]);        

        this._pixQrCode = pixResponse.pixQrCode;
        this._pixQrCodeImage = pixResponse.pixQrCodeImage;
        this._timeRemaining = pixResponse.timeRemaining;
        this._pixValue = pixResponse.pixValue;
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  // startTimer() {
  //   this.timerInterval = setInterval(() => {
  //     if (this._timeRemaining > 0) {
  //       this._timeRemaining--;
  //       this.updateFormattedTime();
  //     } else {
  //       clearInterval(this.timerInterval);
  //       this.openConfirmModal();
  //     }
  //   }, 1000);
  // }

  openConfirmModal() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: "Pix Expirado",
        description: "Pix expirado. Caso não tenha pago o valor do pix, nenhuma cobrança será realizada.",
        buttonConfirm: "Refazer o pedido",
        buttonClose: "Ir para lista de pedidos"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirm) {
        
        this.router.navigate([`/store/checkout-payment/${this._idCheckout}`]);
      }
      else {
        this.router.navigate(['/store/checkout']);
      }
    });
  }

  updateFormattedTime() {
    const minutes = Math.floor(this._timeRemaining / 60);
    const seconds = this._timeRemaining % 60;
    this.formattedTime = `${minutes} minutos e ${seconds < 10 ? '0' : ''}${seconds} segundos`;
  }

  copyPixCode() {
    navigator.clipboard.writeText(this._pixQrCode).then(() => {
      this.alertService.show("Sucesso", "Código PIX copiado.", AlertType.success);
    }).catch(err => {
      this.alertService.show("Erro", "Erro ao copiar código PIX.", AlertType.error);
    });
  }

}
