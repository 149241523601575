export class PaymentRequestStruct {
    idCheckout: number;
    idBusinessCheckout: number;
    comissionPercentual: number;
    consultorComissionTotalValue: number;
    discountValue: number;
    installmentValue: number;
    totalValue: number;
    idPaymentMethod: number;
    quantInstallments: number;
    idCreditCard: number;
    pixCode: string;
    pixQrCodeImage: string;
}