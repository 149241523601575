<app-menu></app-menu>
<div class="container container-done">
    <div class="vertical-aling-out">
        <div class="vertical-aling-inner">
            <div class="row">
                <div class="col-sm-6">
                    <div class="vertical-aling-out">
                        <div class="vertical-aling-inner">
                            <img class="logo-auth" src="assets/images/fashion.app2one.png">
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <h1>Uhuu!</h1>
                    <h2>Seu pedido #{{idCheckout}} foi realizado com sucesso.</h2>
                    <p>A cotação do frete será informado após a separação dos produtos.
                        Acesse a pagina de pedido para conferir mais detalhes.</p>
                    <a routerLink="/store/checkout-details/{{idCheckout}}">Clique aqui para acompanhar seu pedido</a>
                </div>
            </div>
        </div>
    </div>
</div>