import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MaskService {

  constructor() { }

  getMasks(): Masks {
    let masks: Masks = new Masks();
    masks.phone = "(00) 00000-0000||(00) 0000-0000";
    masks.cpf = "000.000.000-00";
    masks.cnpj = "00.000.000/0000-00";
    masks.cpfCnpj = "000.000.000-00||00.000.000/0000-00";
    masks.creditCard = "0000 0000 0000 0000";
    masks.ccvCreditCard = "000";
    masks.cep = "00000-000";
    masks.month = "00";
    masks.year = "0000";
    return masks;
  }

  static getMasks(): Masks {
    let masks: Masks = new Masks();
    masks.phone = "(00) 00000-0000||(00) 0000-0000";
    masks.cpf = "000.000.000-00";
    masks.cnpj = "00.000.000/0000-00";
    masks.cpfCnpj = "000.000.000-00||00.000.000/0000-00";
    masks.creditCard = "0000 0000 0000 0000";
    masks.ccvCreditCard = "000";
    masks.cep = "00000-000";
    masks.month = "00";
    masks.year = "0000";
    return masks;
  }
}

export class Masks {
  public phone: string;
  public cpf: string;
  public cnpj: string;
  public cpfCnpj: string;
  public creditCard: string;
  public ccvCreditCard: string;
  public cep: string;
  public month: string;
  public year: string;
}