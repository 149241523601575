import { Component, OnInit } from '@angular/core';
import { MenuComponent } from "../../../shared/component/menu/menu.component";
import { SearchBarComponent } from "../../../shared/component/search-bar/search-bar.component";
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { CheckoutResponse } from '../../../shared/services/responses/orchestrator-checkout/checkout.response';
import { CheckoutService } from '../../../shared/services/API/orchestator-checkout/checkout.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { LoadingBoxComponent } from '../../../shared/component/loading-box/loading-box.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CheckoutHistoryResponse } from '../../../shared/services/responses/checkout/checkout-history.response';
import { MatDialog } from '@angular/material/dialog';
import { CheckoutItemResumeComponent } from '../../../consult/pages/search-resume-checkout/checkout-item-resume/checkout-item-resume.component';
import { DetailsProductCheckoutForStoreModalComponent } from '../../../shared/component/details-product-checkout-for-store-modal/details-product-checkout-for-store-modal.component';
import { ModalAddressComponent } from '../../../consult/pages/search-resume-checkout/modal-address/modal-address.component';
import { CheckoutAddressRequest } from '../../../shared/services/requests/orchestator-checkout/checkout-address.request';
import { PaymentMethodModalComponent } from './payment-method-modal/payment-method-modal.component';
import { BusinessCheckoutStruct } from '../../../shared/services/structs/orchestrator-checkout/business-checkout.struct';
import { PaymentMethodEnum } from '../../../shared/enum/payment-method.enum';
import { PaymentService } from '../../../shared/services/API/checkout/payment.service';
import { PaymentResponse } from '../../../shared/services/responses/checkout/payment.response';
import { PaymentRequest } from '../../../shared/services/requests/checkout/payment.request';
import { PaymentRequestStruct } from '../../../shared/services/structs/checkout/payment-request.struct';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-checkout-payment',
  standalone: true,
  imports: [
    MenuComponent,
    SearchBarComponent,
    MatExpansionModule,
    MatButtonModule,
    LoadingBoxComponent,
    CommonModule,
    MatIconModule,
    MatSlideToggleModule,
],
  templateUrl: './checkout-payment.component.html',
  styleUrl: './checkout-payment.component.css'
})
export class CheckoutPaymentComponent implements OnInit{
  constructor(  
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private checkoutService:CheckoutService,
    private activatedRoute:ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private paymentService: PaymentService){
  }
  
  public model: FormGroup;
  public isLoading:boolean = false;
  public _checkoutResponse:CheckoutResponse;
  public _idCheckout:number;
  public _checkoutHistoryResponse:CheckoutHistoryResponse;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      idCheckout: [''],
      storeName: [''],
      storeOwnerName: [''],
      idCheckoutStatus: [''],
    });

    this._idCheckout = Number(this.activatedRoute.snapshot.paramMap.get('idCheckout'));

    this.populateListCheckout();
  }

  populateListCheckout(){
    this.checkoutService.getCheckoutToStoreById(this._idCheckout).subscribe({

      next:(checkoutResponse:CheckoutResponse)=>{
        if (checkoutResponse.isError) {
          this.alertService.show("Erro", checkoutResponse.errorDescription, AlertType.error);
          return;
        }

        this._checkoutResponse = checkoutResponse;
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  async createPayment() {
    if (this.isLoading) 
      return;

    this.isLoading = true;

    const isValid = await this.validatePayments();
    
    if (!isValid) {
      this.alertService.show("Erro", "Preencha os métodos de pagamento corretamente", AlertType.error);
      this.isLoading = false;
    }

    let request: PaymentRequest = this.mapCheckoutResponseToPaymentRequest();

    this.paymentService.createPayment(request).subscribe({
      next: (paymentResponse: PaymentResponse) => {
        if (paymentResponse.isError) {
          this.alertService.show("Erro", paymentResponse.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
      
        if (!paymentResponse.havePix) 
          this.router.navigate([`/store/checkout-done-to-store/${this._idCheckout}`]);        
        else 
          this.router.navigate([`/store/checkout-pix-payment/${this._idCheckout}`]);

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  validatePayments(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (!this._checkoutResponse?.listBusinessCheckoutStruct?.length) {
        resolve(false);
        return;
      }
  
      const isValid = this._checkoutResponse.listBusinessCheckoutStruct.every(business => {
        if (!business || !business.idPaymentMethod) {
          return false;
        }
  
        else if (business.idPaymentMethod === PaymentMethodEnum.PIX) {
          return true;
        }
  
        else if (business.idPaymentMethod === PaymentMethodEnum.CREDIT_CARD) {
          return business.numInstallments > 0 && business.idCreditCard > 0;
        }
  
        return false;
      });
  
      resolve(isValid);
    });
  }

  mapCheckoutResponseToPaymentRequest(): PaymentRequest {
    const paymentRequest = new PaymentRequest();
    
    paymentRequest.paymentRequestStructList = this._checkoutResponse.listBusinessCheckoutStruct.map(business => {
      const paymentStruct = new PaymentRequestStruct();
      
      paymentStruct.idCheckout = this._checkoutResponse.checkout.idCheckout;
      paymentStruct.idBusinessCheckout = business.businessCheckout.idBusinessCheckout;
      paymentStruct.comissionPercentual = business.businessCheckout.consultorComission ? business.businessCheckout.consultorComission : 0; 
      paymentStruct.consultorComissionTotalValue = business.totalComission;
      paymentStruct.discountValue = business.businessCheckoutDiscount?.discountValue || 0;
      paymentStruct.totalValue = business.totalPrice;
      
      //Falta olhar total do pagamento caso seja cart]ao de credito
      paymentStruct.idPaymentMethod = business.idPaymentMethod;
      paymentStruct.quantInstallments = business.numInstallments;
      paymentStruct.idCreditCard = business.idCreditCard;

      if (business.idPaymentMethod === PaymentMethodEnum.CREDIT_CARD) {
        paymentStruct.totalValue = business.installmentValue && business.numInstallments ? business.installmentValue * business.numInstallments : 0;
        paymentStruct.installmentValue = business.installmentValue;
      }
  
      return paymentStruct;
    });
  
    return paymentRequest;
  }  

  returnArraySize(size:number){
    return Array(size).fill(4);
  }

  openDalogDetails(idProduct:number){
    const dialogRef = this.dialog.open(DetailsProductCheckoutForStoreModalComponent, {
      data: {
        idProduct: idProduct,
        idBusinessStore: this._checkoutResponse.checkout.idBusinessStore,
        idCheckout: this._checkoutResponse.checkout.idCheckout,
        disable:true
      },
      panelClass: 'dialog-add-item'
    });
  }

  openModalAddress(){
    const dialogRef = this.dialog.open(ModalAddressComponent, {
      data: {
        checkout: this._checkoutResponse.checkout
      },
    });

    dialogRef.afterClosed().subscribe(result => {      
      if (result && result.address)
        this.mapCheckoutAddressRequestToCheckoutAddress(result.address);  
    });
  }

  mapCheckoutAddressRequestToCheckoutAddress(address:CheckoutAddressRequest):void {
    this._checkoutResponse.checkout.zipcode = address.zipcode;
    this._checkoutResponse.checkout.country = address.country;
    this._checkoutResponse.checkout.state = address.state;
    this._checkoutResponse.checkout.city = address.city;
    this._checkoutResponse.checkout.street = address.street;
    this._checkoutResponse.checkout.addressNumber = address.addressNumber;
    this._checkoutResponse.checkout.neighborhood = address.neighborhood;
    this._checkoutResponse.checkout.complement = address.complement;
    this._checkoutResponse.checkout.latitude = address.latitude;
    this._checkoutResponse.checkout.longitude = address.longitude;
  }

  openPaymentMethodModal(businessCheckoutStruct: BusinessCheckoutStruct) { 
    const dialogRef = this.dialog.open(PaymentMethodModalComponent, {
        data: {
          idBusinessCheckout: businessCheckoutStruct.businessCheckout.idBusinessCheckout
        },
        disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result) 
            this.updatePaymentDisplayText(businessCheckoutStruct, result);
        
    });
  }

  updatePaymentDisplayText(businessCheckoutStruct: BusinessCheckoutStruct, paymentInfo: any): void {
    businessCheckoutStruct.hasPaymentInfo = true;
    businessCheckoutStruct.idPaymentMethod = paymentInfo.idPaymentMethod;
    businessCheckoutStruct.idCreditCard = paymentInfo.idCreditCard;
    businessCheckoutStruct.numInstallments = paymentInfo.numInstallments;
    businessCheckoutStruct.installmentValue = paymentInfo.installmentValue;
    
    if (paymentInfo.idPaymentMethod === PaymentMethodEnum.CREDIT_CARD) {
        businessCheckoutStruct.paymentDisplayText = `${paymentInfo.numInstallments}x de ${paymentInfo.installmentValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} - Total: ${paymentInfo.totalValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`;
    } else {
        const totalValue = businessCheckoutStruct.businessCheckoutDiscount 
            ? businessCheckoutStruct.totalPrice - businessCheckoutStruct.businessCheckoutDiscount.discountValue
            : businessCheckoutStruct.totalPrice;
            
        businessCheckoutStruct.paymentDisplayText = `PIX - ${totalValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} à vista`;
    }
  }
}
