import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { LoadingBoxComponent } from '../../../../shared/component/loading-box/loading-box.component';
import { EmptyListComponent } from '../../../../shared/component/empty-list/empty-list.component';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { CheckoutService } from '../../../../shared/services/API/checkout/checkout.service';
import { CheckoutForStoreCheckoutCardListPageStruct } from '../../../../shared/services/structs/checkout/checkout-for-store-checkout-card-list-page.struct';
import { SearchCheckoutPaginatedForStoreRequest } from '../../../../shared/services/requests/checkout/search-checkout-paginated-for-store.request';
import { CheckoutStatusEnum } from '../../../../shared/enum/checkout-status.enum';
import { CheckoutStatusService } from '../../../../shared/services/API/checkout/checkout-status.service';
import { GetLookupCheckoutStatusResponse } from '../../../../shared/services/responses/checkout/get-lookup-checkout-status.response';
import { CheckoutStatus } from '../../../../shared/services/models/checkout/checkout-status.model';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-checkout-not-delivered-list',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    LoadingBoxComponent,
    CommonModule,
    EmptyListComponent,
    RouterLink,
    MatSelectModule,
  ],
  templateUrl: './checkout-not-delivered-list.component.html',
  styleUrl: './checkout-not-delivered-list.component.css'
})

export class CheckoutNotDeliveredListComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private checkoutService: CheckoutService,
    public dialog: MatDialog,
    public authService: AuthService,
    public checkoutStatusService: CheckoutStatusService) {
  }

  public model: FormGroup;
  public isLoading: boolean = false;
  public _page: number = 0;
  public _listCheckoutForStoreCheckoutCardListPageStruct: CheckoutForStoreCheckoutCardListPageStruct[];
  public _listsCheckoutStatus:CheckoutStatus[] = [];    
  public _arrayLoadingSearch: number[] = [1, 1, 1, 1];
  public _isLastPage: boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      idCheckout: [null],
      idCheckoutStatus: [null]
    });   

    this.getAllCheckoutStatus();
    this.clickSearch();

  }

  getAllCheckoutStatus() {
    this.checkoutStatusService.GetAllCheckoutStatus().subscribe({
      next: (response: GetLookupCheckoutStatusResponse) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          return;
        }

        if (response.listsCheckoutStatus?.length > 0) {
          this._listsCheckoutStatus = response.listsCheckoutStatus.filter(
            status => status.idCheckoutStatus !== CheckoutStatusEnum.Delivered
          );
        }

      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  search() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    let request: SearchCheckoutPaginatedForStoreRequest = new SearchCheckoutPaginatedForStoreRequest();

    request.idCheckout = this.model.get('idCheckout')?.value
    request.page = this._page;
    request.idCheckoutStatus = this.model.get('idCheckoutStatus')?.value
    request.isToRemoveCheckoutStatusDelivered = true;

    this.checkoutService.searchCheckoutPaginatedForStore(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          return;
        }

        this._isLastPage = true;

        if (this._page == 0) {
          this._listCheckoutForStoreCheckoutCardListPageStruct = [];
        }

        if (response.listCheckoutForStoreCheckoutCardListPageStruct?.length > 0) {
          this._listCheckoutForStoreCheckoutCardListPageStruct.push(...response.listCheckoutForStoreCheckoutCardListPageStruct);
          this._isLastPage = false;
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  clickSearch() {
    this._page = 0;
    this.search();
  }

  nextPage() {
    this._page = this._page + 1;
    this.search();
  }

  getCheckoutLink(item: CheckoutForStoreCheckoutCardListPageStruct): void { 
    if (item.idCheckoutStatus === CheckoutStatusEnum.WaitingPayment) {
      this.router.navigate([`/store/checkout-payment/${item.idCheckout}`]);
    } 
    else {
      this.router.navigate([`/store/checkout-details/${item.idCheckout}`]);
    }
  }
}
