import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { InstallmentsToCreditCardStruct } from '../../../../shared/services/structs/checkout/installments-to-credit-card.struct';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import { BusinessPaymentMethodService } from '../../../../shared/services/API/checkout/business-payment-method.service';
import { BusinessCreditCardService } from '../../../../shared/services/API/checkout/business-credit-card.service';
import { GetListBusinessCreditCardResponse } from '../../../../shared/services/responses/checkout/get-list-business-credit-card.response';
import { PaymentMethod } from '../../../../shared/services/models/checkout/payment-method.model';
import { BusinessCreditCard } from '../../../../shared/services/models/checkout/business-credit-card.model';
import { PaymentMethodService } from '../../../../shared/services/API/checkout/payment-method.service';
import { GetAllPaymentMethodsResponse } from '../../../../shared/services/responses/checkout/get-all-payment-methods.response';
import { CreateBusinessCreditCardRequest } from '../../../../shared/services/requests/checkout/create-business-credit-card.request';
import { CreateBusinessCreditCardResponse } from '../../../../shared/services/responses/checkout/create-business-credit-card.response';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { Masks, MaskService } from '../../../../shared/services/mask.service';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { UtilService } from '../../../../shared/services/util.service';
import { PaymentMethodEnum } from '../../../../shared/enum/payment-method.enum';

@Component({
    selector: 'app-payment-method-modal',
    standalone: true,
    imports: [        
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        NgxMaskDirective,
    ],
    providers: [
        provideNgxMask()
    ],
    templateUrl: './payment-method-modal.component.html',
    styleUrls: ['./payment-method-modal.component.css']
})
export class PaymentMethodModalComponent implements OnInit {
    public listPaymentMethods: PaymentMethod[] = [];
    public listBusinessCreditCard: BusinessCreditCard[] = [];
    public listInstallmentsToCreditCard: InstallmentsToCreditCardStruct[] = [];
    public paymentMethodForm: FormGroup;
    public creditCardForm: FormGroup;
    public showCreditCardFlow = false;
    public showNewCreditCardForm = false;
    public isLoading = false;
    public minDate: string;
    public masks: Masks;
    public ip: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<PaymentMethodModalComponent>,        
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private businessPaymentMethodService: BusinessPaymentMethodService,
        private businessCreditCardService: BusinessCreditCardService,
        private paymentMethodService: PaymentMethodService,
        private maskService: MaskService,
        private utilService: UtilService
    ) {}

    ngOnInit(): void {
        this.paymentMethodForm = this.formBuilder.group({
            selectedPaymentMethod: ['', Validators.required],
            selectedCreditCard: [''],
            selectedInstallment: ['']
        });

        this.creditCardForm = this.formBuilder.group({
            creditCardNumber: ['', Validators.required],
            securityCCVCode: ['', Validators.required],
            expiryMonth: ['', [Validators.required, Validators.min(1), Validators.max(12)]],
            expiryYear: ['', [Validators.required, Validators.min(new Date().getFullYear())]],
            cardHolderName: ['', Validators.required],
            userName: ['', Validators.required],
            userEmail: ['', [Validators.required, Validators.email]],
            userCpfCnpj: ['', Validators.required],
            userPostalCode: ['', Validators.required],
            userAddressNumber: ['', Validators.required],
            userAddressComplement: [''],
            userPhone: ['', Validators.required],
            userMobilePhone: ['', Validators.required]
        });

        this.masks = this.maskService.getMasks();

        this.getListPaymentMethods();
        this.loadCreditCards();
        this.getInstallmentsToCreditCard();

        const today = new Date();
        this.minDate = today.toISOString().split('T')[0];

        setTimeout(() => { this.getIP(); }, 2000);
    }  

    getListPaymentMethods(): void {
        this.paymentMethodService.GetAllPaymentMethods().subscribe({
            next: (response: GetAllPaymentMethodsResponse) => {
                if (response.isError) {
                    this.alertService.show("Erro", response.errorDescription, AlertType.error);
                    this.isLoading = false;
                    return;
                }
                this.listPaymentMethods = response.listPaymentMethods;
                this.isLoading = false;
            },
            error: (error) => {
                this.alertService.show("Erro inesperado", error, AlertType.error);
                this.isLoading = false;
            }
        });
    }

    loadCreditCards(): void {
        this.businessCreditCardService.GetBusinessCreditCardsForUser().subscribe({
            next: (response: GetListBusinessCreditCardResponse) => {
                if (response.isError) {
                    this.alertService.show("Erro", response.errorDescription, AlertType.error);
                    this.isLoading = false;
                    return;
                }
                this.listBusinessCreditCard = response.listBusinessCreditCard;
            },
            error: (error) => {
                this.alertService.show("Erro inesperado", error, AlertType.error);
                this.isLoading = false;
            }
        });
    }

    getInstallmentsToCreditCard(): void {
        this.businessPaymentMethodService.GetListInstallmentsToCreditCard(this.data.idBusinessCheckout).subscribe({
            next: (response) => {
                if (response.isError) {
                    this.alertService.show("Erro", response.errorDescription, AlertType.error);
                    this.isLoading = false;
                    return;
                }
                this.listInstallmentsToCreditCard = response.listInstallmentsToCreditCard;
            },
            error: (error) => {
                this.alertService.show("Erro inesperado", error, AlertType.error);
                this.isLoading = false;
            }
        });
    }

    getIP() {
        this.utilService.getIP().subscribe({
          next: (response) => {
            if (response && response.ip) 
              this.ip = response.ip;
            
          }
        });
    }

    clickNewCreditCardButton(): void {
        if (this.isLoading)
            return;

        this.showNewCreditCardForm = !this.showNewCreditCardForm
        this.creditCardForm.reset();
    }

    onPaymentMethodChange(selectedPaymentMethod: number): void {
        if (this.isLoading)
            return;

        this.paymentMethodForm.get('selectedCreditCard')?.clearValidators();
        this.paymentMethodForm.get('selectedInstallment')?.clearValidators();
        this.paymentMethodForm.get('selectedCreditCard')?.reset();
        this.paymentMethodForm.get('selectedInstallment')?.reset();
        this.showCreditCardFlow = false;

        if (selectedPaymentMethod === PaymentMethodEnum.CREDIT_CARD) {
            this.paymentMethodForm.get('selectedCreditCard')?.setValidators(Validators.required);
            this.paymentMethodForm.get('selectedInstallment')?.setValidators(Validators.required);
            this.showCreditCardFlow = true;
        } 

        this.paymentMethodForm.get('selectedCreditCard')?.updateValueAndValidity();
        this.paymentMethodForm.get('selectedInstallment')?.updateValueAndValidity();
    }

    saveCreditCard(): void {
        if (this.isLoading) 
            return;

        if (this.creditCardForm.invalid) {
          this.alertService.show("Erro", "Preencha todos os campos obrigatórios.", AlertType.error);
          return;
        }
    
        this.isLoading = true;        
        const request: CreateBusinessCreditCardRequest = this.mapFormToCreateBusinessCreditCardRequest();
    
        this.businessCreditCardService.CreateBusinessCreditCard(request).subscribe({
          next: (response: CreateBusinessCreditCardResponse) => {
            if (response.isError) {
              this.alertService.show("Erro", response.errorDescription, AlertType.error);
              this.isLoading = false;
              return;
            }

            this.isLoading = false;
            this.paymentMethodForm.get('selectedCreditCard')?.setValue(response.idBusinessCreditCard);
            this.showNewCreditCardForm = false;
            this.creditCardForm.reset();
            this.loadCreditCards();
          },
          error: (error) => {
            this.alertService.show("Erro inesperado", error, AlertType.error);
            this.isLoading = false;
          }
        });
    }    

    mapFormToCreateBusinessCreditCardRequest(): CreateBusinessCreditCardRequest {
        return {
          creditCardNumber: this.creditCardForm.get('creditCardNumber')?.value,
          securityCCVCode: this.creditCardForm.get('securityCCVCode')?.value,
          expiryDate: this.getFormattedExpiryDateFromCreditCardForm(),
          cardHolderName: this.creditCardForm.get('cardHolderName')?.value,
          userName: this.creditCardForm.get('userName')?.value,
          userEmail: this.creditCardForm.get('userEmail')?.value,
          userCpfCnpj: this.creditCardForm.get('userCpfCnpj')?.value,
          userPostalCode: this.creditCardForm.get('userPostalCode')?.value,
          userAddressNumber: this.creditCardForm.get('userAddressNumber')?.value,
          userAddressComplement: this.creditCardForm.get('userAddressComplement')?.value,
          userPhone: this.creditCardForm.get('userPhone')?.value,
          userMobilePhone: this.creditCardForm.get('userMobilePhone')?.value,
          ip: this.ip
        };
    }

    getFormattedExpiryDateFromCreditCardForm(): Date {
        const month = this.creditCardForm.get('expiryMonth')?.value;
        const year = this.creditCardForm.get('expiryYear')?.value;
        
        if (!month || !year) 
            return new Date();
        
        // Ensure month is two digits
        const paddedMonth = month.toString().padStart(2, '0');
        return new Date(`${year}-${paddedMonth}-01`);
    }

    submitPaymentMethod(): void {
        if (this.isLoading)
            return;

        if (this.paymentMethodForm.invalid) {
            this.alertService.show("Erro", "Preencha todos os campos obrigatórios.", AlertType.error);
            return;
        }

        const selectedInstallment = this.listInstallmentsToCreditCard.find(i => 
            i.numInstallments === this.paymentMethodForm.get('selectedInstallment')?.value
        );

        this.dialogRef.close({
            idPaymentMethod: this.paymentMethodForm.get('selectedPaymentMethod')?.value,
            idCreditCard: this.paymentMethodForm.get('selectedCreditCard')?.value,
            numInstallments: selectedInstallment?.numInstallments,
            installmentValue: selectedInstallment?.installmentValue,
            totalValue: selectedInstallment?.totalValue
        });
    }

    closeModal(): void {
        if (this.isLoading)
            return;
        
        this.dialogRef.close();
    }
    
}
