import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { PaymentResponse } from '../../responses/checkout/payment.response';
import { PaymentRequest } from '../../requests/checkout/payment.request';
import { PaymentPixResponse } from '../../responses/checkout/payment-pix.response';


@Injectable({
  providedIn: 'root'
})
export class PaymentService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public createPayment(request: PaymentRequest): Observable<PaymentResponse> {
    let url: string = "Payment";
    
    return this.httpClient.post<PaymentResponse>(environment.urlApiCheckout + url, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getPixDataFromPayment(idCheckout: number): Observable<PaymentPixResponse> {
    let url: string = `Payment/GetPix/idCheckout/${idCheckout}`;
    
    return this.httpClient.get<PaymentPixResponse>(environment.urlApiCheckout + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}