import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { GetListInstallmentsToCreditCardResponse } from '../../responses/checkout/get-list-installments-to-credit-card.response';

@Injectable({
  providedIn: 'root'
})
export class BusinessPaymentMethodService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public GetListInstallmentsToCreditCard(idBusinessCheckout: number): Observable<GetListInstallmentsToCreditCardResponse> {
    let url: string = `BusinessPaymentMethod/getListInstallmentsToCreditCard?idBusinessCheckout=${idBusinessCheckout}`;
    
    return this.httpClient.get<GetListInstallmentsToCreditCardResponse>(environment.urlApiCheckout + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}